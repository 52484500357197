.app {
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: #FFF;
}

.main-mint-btn {
  position: relative;
  margin-top: 2.5vh;
  width: 40vh;
  height: 5vh;
  background-color: rgb(255, 210, 0);
  color: #000;
  font-size: 2.5vh;
  text-align: center;
  cursor: pointer;
}

.viewer {
  position: relative;
  margin-top: 5vh;
  width: 100%;
  color: rgb(255, 210, 0);
  font-size: 2vh;
}

#canvas-container {
  position: relative;
}

.logo {
  position: absolute;
  top: 92.5%;
  left: 50%;
  width: 10vh;
  height: 10vh;
  transform: translate(-50%,-50%);
  background-image: url("logo.png");
  background-repeat: no-repeat;
  background-size: contain;
}

